import Constants from './utils/Constants';
import MenuList from './components/MenuList';
import logo from './assets/images/korta_logo_white.svg';

function OpenPdf(pdfPath: string) {
  window.open(pdfPath, '_blank', 'noreferrer');
}

const App = () => {
  return (
    <div id={Constants.MENU} className='menu'>
      <div className='wrapper'>
        <div className='menu_logo'>
          <img src={logo} alt={`${Constants.PROPERTY_NAME} logo`} />
        </div>
        <h1 className='menu_title' hidden>
          Korta Info
        </h1>
        <div className='menu_container'>
          {MenuList.map((menuItem) => (
            <div className='menu_container_item' onClick={() => OpenPdf(menuItem.pdfPath)}>
              <div className='menu_container_item_image'>
                <img src={menuItem.imgPath} alt={menuItem.title} />
              </div>
              <h2>{menuItem.title}</h2>
            </div>
          ))}
        </div>
        <div className='menu_footer'>
          <div className='menu_footer_left'>
            <p>Plinarska ulica 31</p>
            <p>21000 Split, Croatia</p>
            <a href='mailto:reservations@kortasplit.com' className='opacity_transition_low'>
              reservations@kortasplit.com
            </a>
          </div>
          <div className='menu_footer_right'>
            <a href='tel:0038598571220' className='opacity_transition_low'>
              +385 98 571 220
            </a>
            <a href='tel:0038598571226' className='opacity_transition_low'>
              +385 98 571 226
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
