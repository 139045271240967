const PROPERTY_NAME = 'cora';

const MENU = 'menu';

const WIFI = 'WIFI';
const PARKING = 'PARKING';
const EXCURSIONS = 'EXCURSIONS';
const TRANSFERS = 'TRANSFERS';
const INFO_BOOK = 'INFO_BOOK';

const Constants = {
  PROPERTY_NAME,
  MENU,
  WIFI,
  PARKING,
  EXCURSIONS,
  TRANSFERS,
  INFO_BOOK,
};

export default Constants;
